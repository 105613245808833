<template>
  <div class="topmenu">
    <main>
      <a name="top"></a>
      <h1 class="visually-hidden">DATAPAR</h1>

      <div class="menu">
        <header>
          <nav class="navbar navbar-expand-lg navbar-light bg-white">
            <div class="container-fluid py-2 ms-5 me-5">
              <a
                href="/"
                class="d-flex mb-md-0 me-md-auto text-dark text-decoration-none"
              >
                <img
                  src="../assets/images/logo_G.png"
                  alt=""
                  class="img-fluid"
                  style="width: 160px"
                />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarDatapar"
                aria-controls="navbarDatapar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                class="justify-content-end collapse navbar-collapse"
                id="navbarDatapar"
              >
                <ul class="navbar-nav col-md-auto me-4" id="menuPrincipal">
                  <router-link class="nav-link" to="/">{{
                    $t("home").toUpperCase()
                  }}</router-link>

                  <li class="nav-item">
                    <a
                      href="https://productos.datapar.com.py"
                      class="nav-link"
                      id="productos"                      
                      target="_blank"
                    >
                      {{ $t("products").toUpperCase() }}                      
                    </a>
                    <!-- <ul class="dropdown-menu" aria-labelledby="productos">
                      <i class="bi bi-triangle-fill tm"></i>
                      <li>
                        <div class="row col-md-12 drop-menu">
                          <ul class="list-unstyled col-md-6">
                            <li
                              v-for="product in productList"
                              :key="product.id"
                            >
                              <a
                                class="dropdown-item"
                                :href="'/product/' + product.title"
                                ><i class="fas fa-circle"></i>
                                {{ product.title.toUpperCase() }}</a
                              >
                            </li>
                            <li>                              
                               <a class="dropdown-item" href="/complementares" style="text-transform: uppercase;"
                                ><i class="fas fa-circle"></i> Apps Dolphin</a
                              >
                            </li>
                          </ul>
                          <ul class="list-unstyled col-md-6">
                            <li
                              v-for="product in productList2"
                              :key="product.id"
                            >
                              <a
                                class="dropdown-item"
                                :href="'/product/' + product.title"
                                ><i class="fas fa-circle"></i>
                                {{ product.title.toUpperCase() }}</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="https://academia.datapar.com.py/inicio" target="_blank" style="text-transform: uppercase;"
                                ><i class="fas fa-circle"></i> Datapar Academy</a
                              >
                            </li>
                          </ul>
                          <ul class="list-unstyled col-md-12">
                            <li>
                              <a class="dropdown-item" href="https://facturae.com.py" target="_blank" style="text-transform: uppercase;"
                                ><i class="fas fa-circle"></i> Facturación Electrónica</a
                              >
                            </li>
                            
                          </ul>
                        </div>
                      </li>
                    </ul> -->
                  </li>

                  <router-link class="nav-link" to="/segment">{{
                    $t("segment").toUpperCase()
                  }}</router-link>

                  <router-link class="nav-link" to="/cases">{{
                    $t("cases").toUpperCase()
                  }}</router-link>

                  <router-link class="nav-link" to="/about">{{
                    $t("about").toUpperCase()
                  }}</router-link>

                  <!-- 
                    Old Customer Area
                    <router-link class="nav-link" to="/customer">{{
                    $t("client").toUpperCase()
                  }}</router-link> -->

                  <li class="nav-item dropdown">
                    <a
                      href="#"
                      class="nav-link dropdown-toggle"
                      id="customer"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ $t("client").toUpperCase() }}
                      <i class="fa fa-angle-down"></i>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="customer">
                      <i class="bi bi-triangle-fill tm"></i>
                      <li>
                        <div class="row drop-menu">
                          <ul class="list-unstyled">
                            <li>
                              <a class="dropdown-item" href="/customer"
                                ><i class="fas fa-circle"></i>
                                {{ $t("client").toUpperCase() }}</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                target="_blank"
                                href="https://datapar.atlassian.net/wiki/spaces/HC/overview?homepageId=3397845438"
                                ><i class="fas fa-circle"></i> RELEASES NOTES</a
                              >
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <router-link class="nav-link" to="/work">{{
                    $t("workwithus").toUpperCase()
                  }}</router-link>
                  <!-- <li><a href="#" class="nav-link">CONTENIDOS</a></li> -->
                </ul>

                <div class="buscar text-center">
                  <ul class="list-unstyled">
                    <li class="header-search">
                      <div class="nav-search mb-lg-0 me-lg-4">
                        <div
                          class="nav-search-button"
                          data-bs-toggle="modal"
                          data-bs-target="#searchModal"
                        >
                          <i class="fas fa-search"></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="btnlang text-center">
                  <div
                    class="d-block link-dark text-decoration-none"
                    v-if="$i18n.locale == 'es'"
                  >
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'es';
                        changeLanguage('es');
                      "
                      class="btn btn-lang active"
                    >
                      ES
                    </button>
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'pt';
                        changeLanguage('pt');
                      "
                      class="btn btn-lang"
                    >
                      PT
                    </button>
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'en';
                        changeLanguage('en');
                      "
                      class="btn btn-lang"
                    >
                      EN
                    </button>
                  </div>
                  <div
                    class="d-block link-dark text-decoration-none"
                    v-if="$i18n.locale == 'pt'"
                  >
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'es';
                        changeLanguage('es');
                      "
                      class="btn btn-lang"
                    >
                      ES
                    </button>
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'pt';
                        changeLanguage('pt');
                      "
                      class="btn btn-lang active"
                    >
                      PT
                    </button>
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'en';
                        changeLanguage('en');
                      "
                      class="btn btn-lang"
                    >
                      EN
                    </button>
                  </div>
                  <div
                    class="d-block link-dark text-decoration-none"
                    v-if="$i18n.locale == 'en'"
                  >
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'es';
                        changeLanguage('es');
                      "
                      class="btn btn-lang"
                    >
                      ES
                    </button>
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'pt';
                        changeLanguage('pt');
                      "
                      class="btn btn-lang"
                    >
                      PT
                    </button>
                    <button
                      type="button"
                      @click="
                        $i18n.locale = 'en';
                        changeLanguage('en');
                      "
                      class="btn btn-lang active"
                    >
                      EN
                    </button>
                  </div>
                </div>

                <div class="ms-3 text-center">
                  <a href="/work/#AreGPTW"
                    ><img
                      src="../assets/images/GPTWDatapar.png"
                      alt=""
                      class="img-fluid"
                      style="max-height: 50px"
                  /></a>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>

      <div
        class="modal fade"
        id="searchModal"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content border-0 fondo-none">
            <div class="modal-header border-0">
              <button
                type="button"
                class="btn position-absolute top-0 end-0 text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>

            <div class="modal-body">
              <div class="form-floating text-center">
                <input
                  type="text"
                  class="form-control form-control-lg search-modal-input"
                  id="floatingInput"
                  placeholder="{{$t('search')}}..."
                  v-model="searchWord"
                  @change="searchGo"
                />
                <label for="floatingInput" class="search-modal-label"
                  >{{ $t("search") }}...</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div class="text-center share">
      <a :href="aboutItem.urlFacebook" target="_blank">
        <div><i class="fab fa-facebook-f"></i></div>
      </a>
      <a :href="aboutItem.urlInstagram" target="_blank">
        <div><i class="fab fa-instagram"></i></div>
      </a>
      <a :href="aboutItem.urlLinkedin" target="_blank">
        <div><i class="fab fa-linkedin-in"></i></div>
      </a>
    </div>
  </div>
</template>

<script>
import AboutService from "../services/about.service";
import ProductService from "../services/product.service";
import { getCurrentInstance } from "vue";
export default {
  name: "TopMenu",
  props: {
    msg: String,
  },
  data() {
    return {
      options: ["A", "B", "C"],
      value: "B",
      aboutItem: [],
      productList: [],
      productList2: [],
      app: null,
      searchWord: "",
    };
  },
  mounted() {
    this.app = getCurrentInstance();
    this.reload();
  },
  methods: {
    changeLanguage: function (lang) {
      localStorage.locale = lang;
      this.app.appContext.config.globalProperties.$router.go(0);
    },
    reload: function () {
      AboutService.getOne().then((result) => {
        if (result.status < 400) {
          this.aboutItem = result.data;
        }
      });
      ProductService.getAllMenu(localStorage.locale).then((result) => {
        const oringList = result.data;
        const half = Math.ceil(oringList.length / 2);
        this.productList = oringList.slice(0, half);
        this.productList2 = oringList.slice(half);
      });
    },
    searchGo: function (event) {
      event.preventDefault();
      location.href = `/search/${this.searchWord}`;
    },
  },
};
</script>

<style lang="css">
/* CSS router-link Menu */
#nav .datapar-active-link {
  color: #d12e29 !important;
}
</style>
